import axios from 'axios';

// const baseURLConfig = () => {

//     let api = 'https://srv.valsports.net';

//     try {
//         const {pipe} = JSON.parse(window.localStorage.getItem('auth'));
//         return pipe == 3 ? 'https://odin.valsports.net' : api;
//     } catch(e) {
//         return api;
//     }
// }

export const api = axios.create({
    baseURL: 'https://api.futebolmania.org',
    timeout: 1800000
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('x-token');
    config.headers['X-Web-Token'] = true;
    config.headers['X-Access-Token'] = token ? token : undefined;
    return config;
}, (error) => {
	return Promise.reject(error);
});

api.interceptors.response.use((response) => {
	return response;
}, (error) => {
	return Promise.reject(error.response);
});