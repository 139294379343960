<script>
  import Vue from 'vue'
  import {api} from './api'
  import axios from 'axios'
  import VueRouter from 'vue-router'
  import devtools from 'devtools-detect'

  import Home from '@/components/Home'
  import Web from '@/components/Web'
  import Dashboard from '@/components/Dashboard'
  import Events from '@/components/Events'
  import EventsResults from '@/components/EventsResults'
  import Bets from '@/components/Bets'
  import Leagues from '@/components/Leagues'
  import User from '@/components/User'
  import Users from '@/components/Users'
  import Odds from '@/components/Odds'
  import Configs from '@/components/Configs'
  import CashGeneral from '@/components/CashGeneral'
  import CashClients from '@/components/CashClients'
  import CashManager from '@/components/CashManager'
  import CashSalesman from '@/components/CashSalesman'
  import CashSalesmanByManager from '@/components/CashSalesmanByManager'
  import Releases from '@/components/Releases'
  import RulesLeagues from '@/components/RulesLeagues'
  import RulesSalesman from '@/components/RulesSalesman'
  import RulesManagers from '@/components/RulesManagers'
  import RulesClients from '@/components/RulesClients'
  import Challenge from '@/components/Challenge'
  import Ticket from '@/components/Ticket'
  import Register from '@/components/Register'
  import LiveTicket from '@/components/LiveTicket'
  import Transactions from '@/components/Transactions'
  import CommissionStandard  from '@/components/CommissionStandard'
  import LiveEvents from '@/components/EventsLive'
  import Logs from '@/components/Logs'
  import Affiliate from '@/components/Affiliate'
  import Validate from '@/components/Validate'
  import Login from '@/components/Login'

  Vue.use(VueRouter)

  const router = new VueRouter({
      mode: 'history',
      base: __dirname,
      routes: [
        { 
          path: '*', 
          redirect: '/'
        },
        {
          path: '/',
          component: Web
        },
        {
          path: '/login',
          component: Login
        },
        {
          path: '/register',
          component: Register
        },
        {
          path: '/ticket/:code',
          component: Ticket
        },
        {
          path: '/streaming/:code',
          component: LiveTicket
        },
        {
          path: '/affiliate/:code',
          component: Affiliate
        },
        {
          path: '/validate/:code',
          component: Validate
        },
        { 
          path: '/dashboard', 
          component: Dashboard
        },
        {
          path: '/dashboard/events',
          component: Events
        },
        {
          path: '/dashboard/results',
          component: EventsResults
        },
        {
          path: '/dashboard/bets',
          component: Bets
        }, 
        {
          path: '/dashboard/leagues',
          component: Leagues
        },
        {
          path: '/dashboard/user',
          component: User
        },
        {
          path: '/dashboard/users',
          component: Users
        },
        {
          path: '/dashboard/odds',
          component: Odds
        },
        {
          path: '/dashboard/cash/general',
          component: CashGeneral
        },
        {
          path: '/dashboard/cash/manager',
          component: CashManager
        },
        {
          path: '/dashboard/cash/salesman',
          component: CashSalesman
        },
        {
          path: '/dashboard/cash/clients',
          component: CashClients
        },
        {
          path: '/dashboard/cash/salesman-by-manager',
          component: CashSalesmanByManager
        },
        {
          path: '/dashboard/rules-leagues',
          component: RulesLeagues
        },
        {
          path: '/dashboard/rules-managers',
          component: RulesManagers
        },
        {
          path: '/dashboard/rules-salesman',
          component: RulesSalesman
        },
        {
          path: '/dashboard/rules-clients',
          component: RulesClients
        },
        {
          path: '/dashboard/challenge',
          component: Challenge
        },
        {
          path: '/dashboard/releases',
          component: Releases
        },
        {
          path: '/dashboard/configs',
          component: Configs
        },
        {
          path: '/dashboard/transactions',
          component: Transactions
        },
        {
          path: '/dashboard/commission-standard',
          component: CommissionStandard
        }, 
        {
          path: '/dashboard/live-events',
          component: LiveEvents
        }, 
        {
          path: '/dashboard/logs',
          component: Logs
        }
      ]
  })

  let globalData = new Vue({
      data() {
        return {
          home: 1,
          view: 1,
          user_id: 0,
          cadastro: 2,
          pipe: false,
          regras: false,
          prebet: false,
          ticket: false,
          sidebar: false,
          loginModal: false,
          current_credit: 0,
          current_credit_simple: 0,
          geolocation: {
            ip: '',
            pais: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            provedor: ''
          },
          theme_colors: {
            'theme-default': {
              'text': '#212529',
              'bg': '#ffc107'
            },
            'theme-yellow': {
              'text': '#222',
              'bg': '#fff112'
            },
            'theme-red': {
              'text': '#fff',
              'bg': '#ee1d25'
            },
            'theme-purple': {
              'text': '#fff',
              'bg': '#902699'
            },
            'theme-gold': {
              'text': '#fff',
              'bg': '#d0a728'
            },
            'theme-blue': {
              'text': '#fff',
              'bg': '#007bff'
            },
            'theme-blue-light': {
              'text': '#fff',
              'bg': '#24a0e8'
            },
            'theme-green': {
              'text': '#fff',
              'bg': '#28a745'
            },
            'theme-avocado-green': {
              'text': '#fff',
              'bg': '#83b31f'
            },
            'theme-orange': {
              'text': '#fff',
              'bg': '#fd7e14'
            },
            'theme-orange-light': {
              'text': '#fff',
              'bg': '#e64500'
            }
          },
          styleSheet: `
            html.theme-yellow .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #222!important; 
                background-color: #fff112!important; 
            }
            html.theme-gold .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #d0a728!important;
            }
            html.theme-blue-light .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #24a0e8!important;
            }
            html.theme-blue .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #007bff!important;
            }
            html.theme-green .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #28a745!important;
            }
            html.theme-avocado-green .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #83b31f!important;
            }
            html.theme-dark-green .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #02802b!important;
            }
            html.theme-orange .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #fd7e14!important;
            }
            html.theme-orange-light .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #e64500!important;
            } 
            html.theme-orange-min-light .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #ebba30!important;
            } 
            html.theme-orange-min-light-two .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #ffa200!important;
            } 
            html.theme-red .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #ee1d25!important;
            }
            html.theme-purple .ticket .ticket-content .ticket-row-item.ticket-header {
                color: #fff!important;
                background-color: #902699!important;
            }
          `,
          logo: '/images/logo.png?v=2.0',
          image_server: 'https://static.betsnow.net/images',
          service_link: `https://api.betsnow.net/attendance/${window.location.hostname}`,
          isCordova: (window.localStorage.getItem('cordova') || window.location.search.includes('cordova')) ? true : false
        }
      }
  });

  Vue.mixin({
    computed: {
      logo: {
        get() {
          return globalData.$data.logo;
        }
      },
      styleSheet: {
        get() {
          return globalData.$data.styleSheet;
        }
      },
      isCordova: {
        get() {
          return globalData.$data.isCordova;
        },
        set(value) {
          return globalData.$data.isCordova = value;
        }
      },
      geolocation: {
        get() {
          return globalData.$data.geolocation;
        },
        set(value) {
          return globalData.$data.geolocation = value;
        }        
      },
      regras: {
        get() {
          return globalData.$data.regras;
        },
        set(value) {
          return globalData.$data.regras = value;
        }
      },
      service_link: {
        get() {
          return globalData.$data.service_link;
        },
        set(value) {
          return globalData.$data.service_link = value;
        }
      },
      image_server: {
        get() {
          return globalData.$data.image_server;
        }
      },
      current_credit: {
        get() {
          return globalData.$data.current_credit;
        },
        set(value) {
          return globalData.$data.current_credit = value;
        }
      },
      current_credit_simple: {
        get() {
          return globalData.$data.current_credit_simple;
        },
        set(value) {
          return globalData.$data.current_credit_simple = value;
        }
      },
      sidebar: {
        get() {
          return globalData.$data.sidebar;
        },
        set(value) {
          return globalData.$data.sidebar = value;
        }
      },
      loginModal: {
        get() {
          return globalData.$data.loginModal;
        },
        set(value) {
          return globalData.$data.loginModal = value;
        }
      },
      ticket: {
        get() {
          return globalData.$data.ticket;
        },
        set(value) {
          return globalData.$data.ticket = value;
        }
      },
      home: {
        get() {
          return globalData.$data.home;
        },
        set(value) {
          globalData.$data.home = value;
        }
      },
      view: {
        get() { 
          return globalData.$data.view;
        },
        set(value) { 
          globalData.$data.view = value; 
        }
      },
      user_id: {
        get() {
          return globalData.$data.user_id;
        },
        set(value) {
          globalData.$data.user_id = value;
        }
      },
      cadastro: {
        get() {
          return globalData.$data.cadastro;
        },
        set(value) {
          return globalData.$data.cadastro = value;
        }
      },
      prebet: {
        get() {
          return globalData.$data.prebet;
        },
        set(value) {
          globalData.$data.prebet = value;
        }
      }
    },
    methods: {
      getThemeColor(theme) {
        try {
          return this.theme_colors[theme] == undefined ? 'theme-default' : this.theme_colors[theme];
        } catch(e) {
          return this.theme_colors['theme-default'];
        }
      },
      isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop';
      },
      findModality(modality) {

        if (modality == 1) {
            return 'Futebol';
        } else if (modality == 2) {
            return 'Basquete';
        } else if (modality == 3) {
            return 'Lutas';
        } else if (modality == 4) {
            return 'Hóquei';
        } else if (modality == 5) {
            return 'Voleibol';
        } else if (modality == 6) {
            return 'Tênis';
        } else if (modality == 7) {
            return 'E-Sports';
        } else if (modality == 8) {
            return 'Entretenimento';
        } else {
            return 'Acumuladão';
        }
      },
      findStatus(status) {

        if (status == 0) {
          return 'Aguardando';
        } else if (status == 1) {
          return 'Encerrado';
        } else if (status == 3) {
          return 'Adiado';
        } else {
          return 'Cancelado';
        }
      },
      findSituation(situation) {

        if (situation == 0) {
          return 'Aguardando';
        } else if (situation == 1) {
          return 'Acertou';
        } else if (situation == 2) {
          return 'Perdeu';
        } else if (situation == 3) {
          return 'Adiado';
        } else if (situation == 4) {
          return 'Cancelado';
        } else if (situation == 5) {
          return 'Devolvido';
        } else if (situation == 6) {
          return 'Encerrado';
        } else {
          return 'Sem informações'
        }
      },
      pixType(type) {

        if (type == 'email') {
          return 'E-mail';
        } else if (type == 'cpf_cnpj') {
          return 'CPF/CNPJ';
        } else if (type == 'celular') {
          return 'Celular';
        } else {
          return 'Chave aleatória';
        }
      },
      transacoesMap(id, valor) {
        if (id == 1) {
          return `Você acabou de receber uma transferência no valor de R$ ${this.format_coin(valor)}.`;
        } else if (id == 2) {
          return `Você acabou realizar uma aposta no valor de R$ ${this.format_coin(valor)}.`;
        } else if (id == 3) {
          return `Você acabou de receber um bônus no valor de R$ ${this.format_coin(valor)}.`;
        } else if (id == 4) {
          return `Você acabou de realizar um saque no valor de R$ ${this.format_coin(valor)}.`;
        } else if (id == 5) {
          return `Você acabou de receber uma premiação no valor de R$ ${this.format_coin(valor)}.`;
        } else if (id == 6) {
          return `O financeiro confirmou o pagamento no valor de R$ ${this.format_coin(valor)}.`;
        } else if (id == 7) {
          return `Você acabou de receber uma comissão no valor de R$ ${this.format_coin(valor)}.`;
        } else {
          return 'Operação desconhecida!';
        }
      },
      format_coin(n, c, d, t, s, i, j) {
          c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
          return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
      },
      googleMap(latitude, longitude) {
        return `<iframe src="https://maps.google.com/maps?q=${latitude},${longitude}&hl=pt-BR;z=10&amp;output=embed" width="100%" height="250" frameborder="0"></iframe>`;
        // return `<iframe src="https://www.google.com.br/maps/embed/v1/place?key=AIzaSyDq8wiN9ED3dmTgz1prm7OZbSJ2SqYTJ20&q=${latitude},${longitude}&zoom=10" width="100%" height="250" frameborder="0"></iframe>`;
      }
    }
  })

  router.beforeEach((to, from, next) => {

    const publicPages = ['/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('x-token');
    const pipe = localStorage.getItem('firebase:pipe');

    if (!to.path.includes('ticket') && !to.path.includes('streaming') && !to.path.includes('prebet') && !to.path.includes('affiliate') && !to.path.includes('validate') && !to.path.includes('register') && !to.path.includes('login') && !to.path.includes('chat') && authRequired && !loggedIn) {
      return next({ 
        path: '/'
      });
    } 

    if (pipe && loggedIn) {

      if (to.path.includes('dashboard') && (pipe == 1 || pipe == 4)) {
        return next({ 
          path: '/'
        });
      } else if (!to.path.includes('dashboard') && (pipe == 2 || pipe == 3)) {
        return next({ 
          path: '/dashboard'
        });
      }
    }

    next();
  })

  new Vue({ 
    router, 
    render: h => h(Home),
    components: {
      Home
    },
    data() {
      return {
        body: document.body
      }
    },
    methods: {
      setItem(key, value) {
        return Promise.resolve().then(() => {
            window.localStorage.setItem(key, value);
        });
      },
      removeItem(key) {
        return Promise.resolve().then(() => {
            window.localStorage.removeItem(key);
        });
      },
      removeStyle(pipe) {
        if (pipe == 2 || pipe == 3) {
          let themes = ['theme-red','theme-yellow','theme-orange','theme-orange-light','theme-orange-min-light','theme-orange-min-light-two','theme-green','theme-avocado-green','theme-dark-green','theme-blue','theme-blue-light','theme-gold','theme-purple'];
          let classesList = document.getElementsByTagName('html')[0].classList;
          classesList.forEach((value) => {
            if (themes.includes(value)) {
              this.setItem('theme', value).then(() => {
                classesList.remove(value);
              });
            }
          });
        }
      }
    },
    beforeCreate() {
      try {
        if (devtools.isOpen) {
          if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            window.location.href = "about:blank";
          }
        }
        window.addEventListener('devtoolschange', event => {
          if (event.detail.isOpen) {
            if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
              window.location.href = "about:blank";
            }
          }
        });
      } catch(e) {
        window.sessionStorage.setItem('error-before-create', e.message);
      }
    },
    beforeMount() {
      try {
        axios.get('https://api.betsnow.net/geolocation').then((response) => {
          if (response.data.status == 'success') {
            this.geolocation.ip = response.data.query;
            this.geolocation.pais = response.data.country;
            this.geolocation.cidade = response.data.city;
            this.geolocation.estado = response.data.regionName;
            this.geolocation.latitude = response.data.lat;
            this.geolocation.longitude = response.data.lon;
            this.geolocation.provedor = response.data.isp || response.data.org;
          }
        });
      } catch(e) {
        // window.console.log(e.message);
      }
    },
    mounted() {
        document.addEventListener('DOMContentLoaded', () => {

          var page_loading = document.querySelector('.page-loading');
              page_loading.style.display = 'none';

          setTimeout(() => {
            if (!page_loading.getAttribute('style')) {
              page_loading.innerHTML = `
                <div class="d-flex flex-column text-center">
                    <div class="display-2 text-warning">
                      <i class="fab fa-connectdevelop"></i>
                    </div>
                    <p class="text-white">O site demorou para abrir?</p>
                    <button class="btn btn-secondary my-2 my-sm-0" onclick="window.location.reload();">Clique aqui para recarregar</button>
                </div>
              `;
            }
          }, 20000);
        }, false);
    },
    created() {

      const token = window.localStorage.getItem('x-token') || undefined;

      if (typeof token !== 'undefined') {

        api.get('auth', {
          timeout: 10000,
          headers: {
            'X-Access-Token': token
          }
        }).then((response) => {
          this.setItem('auth', JSON.stringify(response.data)).then(() => {
            this.setItem('firebase:pipe', response.data.pipe).then(() => {
              this.user_id = response.data.id;
              this.removeStyle(response.data.pipe);
            });
          });
        }).catch(() => {
          this.removeItem('auth').then(() => {
            this.removeItem('x-token').then(() => {
              this.removeItem('firebase:pipe').then(() => {
                window.location.href = '/';
              });
            });
          });
        });
      } 
      else 
      {
        this.removeItem('auth');
      }
    }
  }).$mount('#app')
</script>
